import ApiService from 'api-client/ApiService'
// import Bugsnag from 'utils/bugsnag'
import config from 'app-config'

class HaMgr extends ApiService {
  public getClassName() {
    return 'haMgr'
  }

  protected async getEndpoint() {
    return Promise.resolve(config.apiHost)
  }

  get baseUrl(): string {
    return '/hamgr/v1/ha'
  }

  getHaConfig = async (aggregateId) => {
    return this.client.basicGet({
      url: `${this.baseUrl}/${aggregateId}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getHaConfig',
      },
    })
  }

  enableHa = async (aggregateId) => {
    const body = {}
    return this.client.basicPut({
      url: `${this.baseUrl}/${aggregateId}/enable`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'enableHa',
      },
    })
  }

  disableHa = async (aggregateId) => {
    const body = {}
    return this.client.basicPut({
      url: `${this.baseUrl}/${aggregateId}/disable`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'disableHa',
      },
    })
  }
}

export default HaMgr
