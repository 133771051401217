import ApiService from 'api-client/ApiService'

class Heat extends ApiService {
  public getClassName() {
    return 'heat'
  }

  protected async getEndpoint() {
    return this.client.keystone.getServiceEndpoint('heat', 'public')
  }

  getStacks = async () => {
    const response = await this.client.basicGet<any>({
      url: `/stacks`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getStacks',
      },
    })
    return response.stacks
  }

  async getStack({ id, name }) {
    const response = await this.client.basicGet<any>({
      url: `/stacks/${name}/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getStack',
      },
    })
    return response.stack
  }

  async createStack(body) {
    const response = await this.client.basicPost<any>({
      url: `/stacks`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createStack',
      },
    })
    return response.stack
  }

  async updateStack({ id, body }) {
    const response = await this.client.basicPut<any>({
      url: `/stacks/${id}`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateStack',
      },
    })
    return response.stack
  }

  deleteStack = async ({ id, name }) => {
    return this.client.basicDelete<any>({
      url: `/stacks/${name}/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteStack',
      },
    })
  }

  async getStackResources({ id, name }) {
    const response = await this.client.basicGet<any>({
      url: `/stacks/${name}/${id}/resources?nested_depth=1000`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getStack',
      },
    })
    return response.resources
  }
}

export default Heat
