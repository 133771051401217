/* eslint-disable max-len */
import { Route } from 'core/plugins/route'
import Bugsnag from 'utils/bugsnag'

// Data is sent to Segment using window.analytics

// name & body are both optional for both page & event
export const trackPage = (location) => {
  const route = Route.find(location)
  if (!route) {
    console.info(`No route found for '${location}'. Consider registering it as a Route.`)
    return
  }

  // Tracking for Segment
  if (window.analytics) {
    if (route.tab) {
      const routeParams = route.pattern.match(location)
      const hashValue = routeParams[route.tab]
      window.analytics.page(`${route.name}:${hashValue}`)
    } else {
      window.analytics.page(route.name)
    }
  }

  Bugsnag.leaveBreadcrumb(route.name, {}, 'navigation')
}

export const trackEvent = (name, body = {}) => {
  if (!window.analytics) {
    return
  }

  window.analytics.track(name, body)
}

export const trackUpgrade = ({ username, customerTier }) => {
  trackEvent(`Upgrade from ${customerTier}`, { username, du: window.location.host })
}

export const segmentScriptContent = `
"use strict";
 !function(){var i="analytics",analytics=window[i]=window[i]||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","screen","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware","register"];analytics.factory=function(e){return function(){if(window[i].initialized)return window[i][e].apply(window[i],arguments);var n=Array.prototype.slice.call(arguments);if(["track","screen","alias","group","page","identify"].indexOf(e)>-1){var c=document.querySelector("link[rel='canonical']");n.push({__t:"bpc",c:c&&c.getAttribute("href")||void 0,p:location.pathname,u:location.href,s:location.search,t:document.title,r:document.referrer})}n.unshift(e);analytics.push(n);return analytics}};for(var n=0;n<analytics.methods.length;n++){var key=analytics.methods[n];analytics[key]=analytics.factory(key)}analytics.load=function(key,n){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.setAttribute("data-global-segment-analytics-key",i);t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(t,r);analytics._loadOptions=n};analytics._writeKey="Fwpf5ncfE9AbSQwnuP88lclZTFByCdXy";;analytics.SNIPPET_VERSION="5.2.0";
  analytics.load("Fwpf5ncfE9AbSQwnuP88lclZTFByCdXy");
  analytics.page();
  }}();
`

export const driftScriptContent = `
"use strict";
!function() {
var t = window.driftt = window.drift = window.driftt || [];
if (!t.init) {
if (t.invoked) return void (window.console && console.error && console.error("Drift snippet included twice."));
t.invoked = !0, t.methods = [ "identify", "config", "track", "reset", "debug", "show", "ping", "page", "hide", "off", "on" ],
t.factory = function(e) {
return function() {
var n = Array.prototype.slice.call(arguments);
return n.unshift(e), t.push , t;
};
}, t.methods.forEach(function(e) {
t[e] = t.factory(e);
}), t.load = function(t) {
var e = 3e5, n = Math.ceil(new Date() / e) * e, o = document.createElement("script");
o.type = "text/javascript", o.async = !0, o.crossorigin = "anonymous", o.src = "https://js.driftt.com/include/" + n + "/" + t + ".js";
var i = document.getElementsByTagName("script")[0];
i.parentNode.insertBefore(o, i);
};
}
}();
drift.SNIPPET_VERSION = '0.3.1';
drift.load('ubawwtd8c4gd');
`

export const appCuesSetAnonymous = () => {
  if (window.Appcues) {
    window.Appcues.anonymous()
  }
}
